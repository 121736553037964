<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/testing/6-1/xit (1).jpg">
					<img class="" src="@/assets/images/testing/6-1/xit (2).jpg">
					<img class="" src="@/assets/images/testing/6-1/xit (3).jpg">
					<img class="" src="@/assets/images/testing/6-1/xit (4).jpg">
					<img class="" src="@/assets/images/testing/6-1/xit (5).jpg">
					<img class="" src="@/assets/images/testing/6-1/xit (6).jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"系统漏洞扫描",
			content:"系统漏洞扫描是通过检测目标主机不同端口的服务，在获得目标主机端口和其对应的系统服务相关信息后，与漏洞库数据进行匹配，确认是否存在漏洞，对主机系统的安全进行评估，并提出相应的整改策略",
            cc:"系统漏洞扫描是通过检测目标主机不同端口的服务，在获得目标主机端口和其对应的系统服务相关信息后，与漏洞库数据进行匹配，确认是否存在漏洞，对主机系统的安全进行评估，并提出相应的整改策略",
			price:'在线咨询',
			url:require('../../assets/images/testing/6-1/wfkxt.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







